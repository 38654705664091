
import { Component, Vue } from 'vue-property-decorator';

import Player from './Player.vue';
import VersusIcon from './VersusIcon.vue';
import Roulette from './Roulette.vue';

@Component({ components: { Player, VersusIcon, Roulette } })
class BonusPage extends Vue {
    private mounted(){
        this.start();   
    }

    private number: number = 3;
    private loading = false;
    private started = false;
    private category = '';

    public start(){

        setTimeout(() => {
            this.loading = true;

            setTimeout(() => {
                this.number -= 1;
                const interval = setInterval(() => {
                    this.number -= 1;
                    if(this.number < 1){
                        clearInterval(interval);
                        setTimeout(() => {
                            this.started = true;
                        }, 150);
                   
                    }
                }, 1000);
            }, 450);

        }, 300);

    }
}

export default BonusPage;
