
import { Component, Vue } from 'vue-property-decorator';

@Component({ })
class Roulette extends Vue {
    private mounted() {
        setTimeout(() => {
            this.category = Math.floor(Math.random() * (10 - 1 + 1) + 1);
        }, 50);

        setTimeout(() => {
            this.$emit('categorySelected', this.categoryName);
        }, 4100);
    }
    private color(n: number) {
        if(n == 2 || n == 4 || n == 7 || n == 9)
            return '#FBB736';
        else if(n == 1 || n == 5)
            return '#C4C4C4';
        else
            return '#E8E8E8';
    }

    private category = -1;

    private get categoryName() {
        const categories = ['Sinaliza��o', 'Primeiros Socorros', 'Mec�nica', 'Dire��o Defensiva', 'Meio Ambiente'];
        const category = (this.category - 1) > 4 ? this.category - 6 : this.category - 1;
        return this.category > 0 ? categories[category] : '';
    
    }
}

export default Roulette;
