
import { Component, Vue } from 'vue-property-decorator';
import Player from './Player.vue';
import VersusIcon from './VersusIcon.vue';
import ThemeItem from './ThemeItem.vue';

@Component({ components: { Player, VersusIcon, ThemeItem } })
class Theme extends Vue {
    private selectedTheme: string = '';
}

export default Theme;
