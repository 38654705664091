
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class Selector extends Vue {

    @Prop({ type: Array, required: true })
    private sections!: string[];

    private selectedIndex: number = 0;
    private transition = 'slide';

    private selected(index: number) {
        this.transition = this.selectedIndex < index ? 'slideIn' : 'slideOut';
        this.selectedIndex = index;
    }
}

export default Selector;
