
import { Component, Vue } from 'vue-property-decorator';
import Selector from '@/components/p-Selector.vue';
import UserListItem from './UsersListItem.vue';

@Component({ components: { Selector, UserListItem } })
class VersusMainPage extends Vue {

}

export default VersusMainPage;
