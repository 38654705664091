
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class Player extends Vue {
    @Prop({ type: Boolean, default: false })
    private playerTwo!: boolean;
}

export default Player;
