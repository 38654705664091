
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class Theme extends Vue {
    @Prop({ type: String, required: true })
    private value!: string;
    
    @Prop({ type: String, required: true })
    private selectValue!: string;

    private get radioValue(){
        return this.value;
    }

    private set radioValue(value: string) {
        this.$emit('input', value);
    }

}

export default Theme;
